import React, { useEffect } from 'react';

const EventCarbonCalculatorPreview = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://public-server-n3bj.onrender.com/scripts/e/event-carbon-calculator.min.js";
        script.async = true;
        document.getElementById('event-calculator-preview').appendChild(script);
    }, []);

    return (
        <div id="event-calculator-preview">
            <div className="event-carbon-calculator-widget"></div>
        </div>
    );
};

export default EventCarbonCalculatorPreview;
