import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Button, Card, CardBody } from 'reactstrap';
import UserContext from "../../contexts/user/UserContext";
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import "../../assets/css/offset.css"; // Create and import your CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf, faFile, faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner";
import logo from '../../assets/images/nz-logo.png'
import bg from '../../assets/images/subtle-prism.png'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const Offset = () => {
    const { user } = useContext(UserContext);
    const [orders, setOrders] = useState([]);
    const [apiKey, setApiKey] = useState("");
    const navigate = useNavigate();
    const { company } = useOutletContext();
    const [loading, setLoading] = useState(true);

    const handleCertificateClick = async (company, orders) => {
        let portfolioResponse;
        try {
            portfolioResponse = await http.get(`${API_ENDPOINTS.GET_PORTFOLIO}?portfolioId=${orders.lastOrderDetails.credits[0].portfolio}`);
            const certificateContainer = document.createElement('div');
            certificateContainer.id = 'certificate';
            certificateContainer.style.width = '1200px';
            certificateContainer.style.height = '800px';
            certificateContainer.style.background = '#f0f8f5';
            certificateContainer.style.padding = '50px';
            certificateContainer.style.border = '10px solid #3CB371';
            certificateContainer.style.position = 'relative';
            certificateContainer.style.fontFamily = 'Arial, sans-serif';
            certificateContainer.style.textAlign = 'center';
            certificateContainer.style.backgroundImage = bg;
            certificateContainer.style.backgroundSize = 'contain';

            const floralBanner = document.createElement('div');
            floralBanner.style.background = '#3CB371';
            floralBanner.style.color = '#fff';
            floralBanner.style.padding = '20px';
            floralBanner.style.fontSize = '24px';
            floralBanner.innerHTML = `<h1 style="margin: 0;">CERTIFICATE OF VOLUNTARY CARBON ACTION</h1>`;

            const centerContent = document.createElement('div');
            centerContent.style.background = '#ffffff';
            centerContent.style.padding = '30px';
            centerContent.style.margin = '50px auto';
            centerContent.style.borderRadius = '20px';
            centerContent.style.boxShadow = '0 0 30px rgba(0, 0, 0, 0.2)';
            centerContent.innerHTML = `
        <h2 style="font-size: 20px; color: #3CB371;">Issued to:</h2>
        <h3 style="font-size: 28px; color: #333;">${company.name}</h3>
        <h2 style="font-size: 32px; color: #333;">${orders.lastOrderDetails.credits[0].amount},000 kg of CO₂e</h2>
        <p style="font-size: 16px; color: #666;"><strong>Order #:</strong> ${orders.lastOrderDetails.id || 'N/A'}</p>
        <p style="font-size: 16px; color: #666;"><strong>Purchased on:</strong> ${orders.lastOrderDetails.date.split('T')[0]}</p>
        <p style="font-size: 16px; color: #666;"> <img src=${logo} alt="logo" width="100" height="100">
</p>
    `;

            // Generate the dynamic footer content based on the projects data
            const certificateFooter = document.createElement('div');
            certificateFooter.style.position = 'absolute';
            certificateFooter.style.bottom = '40px';
            certificateFooter.style.width = '100%';
            certificateFooter.style.textAlign = 'center';
            certificateFooter.style.color = '#3CB371';
            certificateFooter.style.fontSize = '14px';

            // Create the footer's inner HTML by looping through the projects array
            let footerContent = '<div style="display: flex; justify-content: space-around; padding: 0 80px;">';
            portfolioResponse.data.portfolio.projects.forEach(project => {
                footerContent += `
            <p><strong>${project.name}</strong><br />${project.type}—${project.description}</p>
        `;
            });
            footerContent += '</div>';

            certificateFooter.innerHTML = footerContent;

            certificateContainer.appendChild(floralBanner);
            certificateContainer.appendChild(centerContent);
            certificateContainer.appendChild(certificateFooter);
            document.body.appendChild(certificateContainer);

            html2canvas(certificateContainer).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('l', 'mm', 'a4');
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save("cc--certificate.pdf");
                document.body.removeChild(certificateContainer);
            });
        } catch (error) {
            console.log(error)
        }

    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const ordersResponse = await http.get(`${API_ENDPOINTS.COMPANY_ORDERS_SUMMERY}?companyId=${company._id}`);
                setOrders(ordersResponse.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
            finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [user]);

    const handlePurchaseClick = () => {
        navigate('/profile/purchase');
    }

    const handleCertificate = () => {
        handleCertificateClick(company, orders)
    }


    const handleimpactClick = async () => {
        navigate('/profile/share-impact', { state: { orders } })
    }

    const handleOrderDetailClick = () => {
        navigate(`/profile/order/${orders?.lastOrderDetails?.id}`);
    }

    return (
        <>
            <Container className="dashboard">
                <Row className="mb-4">
                    <Col>
                        <button color="primary" className="purchase-credits-btn" onClick={handlePurchaseClick}>Purchase credits</button>
                    </Col>
                </Row>
                <hr style={{ border: '1px solid #000', width: '90%', margin: '10px auto' }} />

                <Row>
                    <Col lg={3} md={6} sm={12} className="mb-4">
                        <Card className="info-card same-size-card card" style={{ borderRadius: '25px'}}>
                            {loading ? <Spinner /> : <CardBody>
                                <FontAwesomeIcon icon={faLeaf} size="2x" style={{ color: "#63E6BE" }} />
                                <h3>{orders.totalCreditsPurchased} tonne</h3>
                                <p>CO₂e offset</p>
                                <div></div>
                                <div></div>
                            </CardBody>}
                        </Card>
                    </Col>
                    <Col lg={3} md={6} sm={12} className="mb-4">
                        <Card className="info-card same-size-card card" style={{ borderRadius: '25px'}}>
                            {loading ? <Spinner /> : <CardBody>
                                <FontAwesomeIcon size="2x" icon={faFile} />
                                <h3>{orders.totalOrderAmount}</h3>
                                <p>Orders fulfilled</p>
                            </CardBody>}
                        </Card>
                    </Col>
                    <Col lg={3} md={6} sm={12} className="mb-4">
                        <Card className="info-card same-size-card card" style={{ borderRadius: '25px'}}>
                            <CardBody>
                                <FontAwesomeIcon size="xl" icon={faBullhorn} style={{ color: "#e05910", }} />
                                <h3 class="sh">Share</h3>
                                <p>Tell the world about your climate impact.</p>
                                <button className="small-button" onClick={handleimpactClick}>View impact page</button>
                                <button className="small-button">Settings</button>
                            </CardBody>

                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} className="mb-4">
                        <Card className="order-card same-size-card-bottom card" style={{ borderRadius: '25px'}}>
                            {loading ? <Spinner /> : <CardBody>
                                <h3>Your most recent order</h3>
                                <p class="marginbottom"><span class='bolded'>Amount CO₂e:</span> {orders?.lastOrderDetails?.credits[0].amount},000 kg</p>
                                <p class="marginbottom"><span class='bolded'>Price:</span> SAR {orders?.lastOrderDetails?.totalAmount}.00</p>
                                <p class="marginbottom"><span class='bolded'>Placed on:</span> {orders?.lastOrderDetails?.date.split('T')[0]}</p>
                                <Button color="link" className="view-details-btn" onClick={handleOrderDetailClick}>View details</Button>
                                <Button color="link" className="view-certificate-btn" onClick={handleCertificate}>View certificate</Button>
                            </CardBody>}
                        </Card>
                    </Col>
                    <Col lg={6} className="mb-4">
                        <Card className="api-card same-size-card-bottom card" style={{ borderRadius: '25px'}}>
                            <CardBody>
                                <h3>c-mass API</h3>
                                <p>Complete the API setup to begin using the live API.</p>
                                {/*<p>Sandbox API key: <code>{apiKey}</code></p>
                                <button color="secondary" className="small-button">Setup API</button>*/}
                                <Button color="link" className="api-docs-btn">Explore the API documentation.</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Offset;
