import React from 'react';
import NavbarQuiz from "../../Layout/NavbarQuiz";
import { Col, Container, Row, Button } from "reactstrap";
import { useNavigate } from 'react-router-dom';

const BeforeStartPage = () => {
  const navigate = useNavigate()

  const handleNextClick = () => {
    navigate('/quiz/time-period');

  };

  return (
    <React.Fragment>
      <NavbarQuiz />
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
            <Row className="align-items-center">
              <Col lg={6}>
                <h2>Before we start</h2>
                <p>No need to be perfect, but the more data you put into the calculator, the more accurate and credible your emissions estimate will be.</p>
                <p>c-mass’s comprehensive emissions engine will attach CO2 equivalents to the business expenses you provide (large and small). All data that we ask for either factors into your overall emissions estimate or industry benchmarking.</p>
                <p>Your profile saves as you go, so you can pick up where you left off or edit what you previously entered.</p>
                <button onClick={handleNextClick}  className="btn btn-primary mt-2">
                  Start
                </button>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default BeforeStartPage;
