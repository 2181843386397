import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import "../../assets/css/orderPage.css"; // Create and import your CSS file
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf, faWeightScale } from '@fortawesome/free-solid-svg-icons';

const OrderPage = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await http.get(`${API_ENDPOINTS.ORDER_DETAILS}/${orderId}`);
        setOrder(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  return (
    <div className="order-page">
      {loading ? <Spinner /> : (
        <Container>
          <Row className="order-header mt-4">
            <Col md="6">
              <h4 className="order-id">Order ID: <span className="order-id-value">{order.id}</span></h4>
              <h1 className="order-title">Order Details</h1>
              <ul className="order-details-list">
                <li><strong>Placed On:</strong> {new Date(order.date).toLocaleString()}</li>
                <li><strong>Amount:</strong> {order.credits[0].amount} tonnes</li>
                <li><strong>Portfolio:</strong> {order.portfolio}</li>
                <li><strong>Purchased By:</strong> {order.purchasedBy}</li>
              </ul>
            </Col>
          </Row>

          <Row className="fulfilled-section mt-5">
            <Col>
              <h2 className="fulfilled-title">Fulfilled With</h2>
              <div className="fulfilled-projects-container">
                {order.fulfilledProjects?.map((project, index) => (
                  <div key={index} className="fulfilled-project">
                    <h4 className="project-name">{project.name}</h4>
                    <p className="project-type">{project.type}</p>
                    <ul className="project-details">
                      <li><FontAwesomeIcon icon={faWeightScale} /> {(order.credits[0].amount / order.fulfilledProjects.length).toFixed(2)} kg</li>
                      <li><i className="bi bi-building"></i> {project.location}</li>
                      <li><i className="bi bi-check-circle"></i> GCOM ID: <a href='https://registry.verra.org/app/projectDetail/VCS/2250'>2250</a></li>
                    </ul>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default OrderPage;
