import React, { useState, useEffect } from 'react';
import PieChartScope from '../../components/PieChartScope';
import PieChart from '../../components/PieChart';
import DonutChart from '../../components/DonutChart';
import http from '../../frameworks/basic-rest/http';
import { API_ENDPOINTS } from '../../frameworks/basic-rest/api-endpoints';
import { Col, Container, Row, Button } from "reactstrap";
import { useNavigate, useOutletContext } from 'react-router-dom';
import TimePeriodDropdown from '../../components/TimePeriodDropdown';
import Spinner from "react-bootstrap/Spinner";
import generatePDF from '../../utils/reports/generate-reports';
import '../../assets/css/dashboard.css';

const Dashboard = () => {
    const { company, itimePeriod, loading } = useOutletContext();
    const [timePeriod, setTimePeriod] = useState(itimePeriod);
    const [totalEmissions, setTotalEmissions] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
    }, [totalEmissions]);

    const handleButtonClick = async () => {
        try {
            const response = await http.get(`${API_ENDPOINTS.FULL_REPORT}?timePeriod=${encodeURIComponent(timePeriod)}&companyId=${company._id}`);
            const result = response.data;

            const reportData = {
                companyName: company.name || "",
                country: company.location || "",
                sector: company.industry || "",
                period: timePeriod || "",
                totalEmissions: result.totalEmissions || "0",
                scope1Data: result.scope1Data,
                scope2Data: result.scope2Data,
                scope3Data: result.scope3Data,
            };

            generatePDF(reportData);
        } catch (error) {
            console.error('Error fetching report data:', error);
        }
    };

    const handleOffsetClick = () => {
        navigate('/profile/offset', { state: { company } });
    };

    const handleAddPeriod = () => {
        navigate('/quiz/time-period');
    };

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (
                <Container fluid>
                    <Row className="mb-4 align-items-center">
                        <Col xs="12" md="3" className="mb-3 mb-md-0">
                            <TimePeriodDropdown className="custom-dropdown" timePeriods={company.timePeriod || []} setTimePeriod={setTimePeriod} />
                        </Col>
                        <Col xs="12" md="9" className="d-flex justify-content-end">
                            <div className="buttons d-flex justify-content-end">
                            <Button color="primary" className="me-3" style={{ borderRadius: '25px', padding: '6px 14px'  }} onClick={handleAddPeriod}>
                                    Add time period +
                                </Button>
                                <Button outline color="secondary" className="me-3" style={{ borderRadius: '25px', padding: '6px 14px'  }} onClick={handleAddPeriod}>
                                    Edit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <hr style={{ border: '1px solid #000', width: '90%', margin: '10px auto' }} />
                    <div className="position-relative" style={{ zIndex: "1" }}>
                        <Row className="align-items-center">
                            <Col xs="12" md="6" className="mb-4 mb-md-0">
                                <div className="overview-section">
                                    <h1>Estimated total emissions</h1>
                                    <h1>{totalEmissions ? totalEmissions.toFixed(2) : <Spinner />} tonnes CO<sub>2</sub>e</h1>
                                    <p className="disclaimer">
                                        Your emissions estimate is only based on the expenses and activities you provide. Missing expenses or activities would mean your full carbon footprint is not covered. Check out the Business Carbon Calculator methodology to better understand how we estimate your emissions.
                                    </p>
                                </div>
                            </Col>
                            <Col xs="12" md="6">
                                <div className="chart-section">
                                    <PieChartScope setTotalEmissions={setTotalEmissions} timePeriod={timePeriod} company={company} />
                                    <Button outline style={{ borderRadius: '25px', padding: '6px 14px' }} onClick={handleButtonClick}>View Full Report</Button>
                                </div>
                            </Col>
                        </Row>
                        <Row className="sub-section">
                            <Col xs="12" md="6" className="mb-4 mb-md-0">
                                <div className="sub-chart-section">
                                    <PieChart totalEmissions={totalEmissions} timePeriod={timePeriod} company={company} />
                                </div>
                            </Col>
                            <Col xs="12" md="6" className="mb-4 mb-md-0">
                                <div className="sub-chart-section">
                                    <DonutChart timePeriod={timePeriod} company={company} />
                                </div>
                            </Col>
                        </Row>
                        <Row className="scope-info-section mt-5">
                            <Col xs="12" md="4" className="mb-4 mb-md-0">
                                <h4>Scope 1</h4>
                                <p>Direct emissions from the combustion of fuel in assets that a company operates, such as fuel emissions from company-owned cars, diesel generators, gas boilers, and air-conditioning leaks.</p>
                            </Col>
                            <Col xs="12" md="4" className="mb-4 mb-md-0">
                                <h4>Scope 2</h4>
                                <p>Indirect emissions from the generation of energy purchased from a utility provider, such as heating, cooling, steam, and electricity.</p>
                            </Col>
                            <Col xs="12" md="4">
                                <h4>Scope 3</h4>
                                <p>All indirect greenhouse gas emissions that do not fall under scope 2 - upstream and downstream. This includes emissions from purchased goods and services, capital goods, upstream transport and distribution, and business travel.</p>
                            </Col>
                        </Row>
                        <Row className="action-section align-items-center">
                            <Col xs="12" md="5" className="mb-4 mb-md-0">
                                <h3>Turn your estimate into action</h3>
                                <p>Understanding your company’s emissions is an important step in your journey to net zero, but it shouldn’t end there. Go to the SME Climate Hub to make your public commitment to net zero, access tangible tips for reducing your emissions and get recognized for being a climate leader.</p>
                                <Button color="primary" style={{borderRadius: '25px', padding: '6px 14px'}} className="action-button" onClick={handleOffsetClick}>Offset your emissions</Button>
                            </Col>
                            <Col xs="12" md="5">
                                <h3>Benchmark against companies in your sector</h3>
                                <p>On our Industry CO₂ Insights page you can benchmark your greenhouse gas emissions against similar companies in your sector and country. This will help you understand how your company is performing on its path to net zero.</p>
                                <Button outline color="secondary" style={{ borderRadius: '25px', padding: '6px 14px' }} className="benchmark-button">Industry CO₂ insights</Button>
                            </Col>
                        </Row>
                    </div>
                </Container>
            )}
        </>
    );
};

export default Dashboard;
