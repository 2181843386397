import React, { useState, useContext } from 'react';
import { QuizContext } from '../../contexts/quiz/QuizContext';
import { Link } from 'react-router-dom';
import NavbarQuiz from "../../Layout/NavbarQuiz";
import { Col, Container, Row, Button } from "reactstrap";
import { useNavigate } from 'react-router-dom';

const VatNumberPage = () => {
  const [vatNumber, setVatNumber] = useState('');
  const { updateQuizData } = useContext(QuizContext);
  const navigate = useNavigate()

  const handleInputChange = (e) => {
    setVatNumber(e.target.value);
  };

  const handleNextClick = () => {
    if (vatNumber) {
      updateQuizData({ vatNumber });
      navigate('/quiz/company-location');
    }
  };

  return (
    <React.Fragment>
      <NavbarQuiz />
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
            <Row className="align-items-center">
              <Col lg={6}>
                <h2>What is the VAT identification number for your company?</h2>
                <p>If you don’t know what your VAT identification number is, or you don’t have a VAT identification number, please answer N/A.</p>
                <div className="mt-4">
                  <input
                    type="text"
                    value={vatNumber}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="VAT"
                  /></div>
                <div className="mt-4">
                  <button onClick={handleNextClick} disabled={!vatNumber} className="btn btn-primary mt-2">
                    Next
                  </button></div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default VatNumberPage;
