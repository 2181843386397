// src/components/PieChart.js
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import http from '../frameworks/basic-rest/http';
import { API_ENDPOINTS } from '../frameworks/basic-rest/api-endpoints';
import Spinner from 'react-bootstrap/Spinner';
import { useOutletContext } from 'react-router-dom';

const PieChart = ({ totalEmissions, timePeriod }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const { company } = useOutletContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ordersResponse = await http.get(`${API_ENDPOINTS.COMPANY_ORDERS_SUMMERY}?companyId=${company._id}`);
        setOrders(ordersResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [company]);

  const options = {
    series: [totalEmissions, orders.totalCreditsPurchased? orders.totalCreditsPurchased : 0],
    chart: {
      width: '100%',
      type: 'pie',
    },
    labels: ['Total Emissions', 'Offset'],
    legend: {
      show: true,
        position: 'bottom',
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: '100%'
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <h1>Total Emissions/Offset</h1>
          {loading ? <Spinner /> : <Chart
            options={options}
            series={options.series}
            type="pie"
            width="100%"
          />}
        </div>
      </div>
    </div>
  );
};

export default PieChart;
