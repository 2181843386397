// src/components/PieChartScope.js
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import http from '../frameworks/basic-rest/http';
import { API_ENDPOINTS } from '../frameworks/basic-rest/api-endpoints';
import Spinner from 'react-bootstrap/Spinner';

const PieChartScope = ({ setTotalEmissions, timePeriod, company }) => {
  const [emissionsData, setEmissionsData] = useState([0, 0, 0]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (!timePeriod) {
        console.log("timePeriod is not available, skipping request.");
        return; // Exit early if timePeriod is not yet available
      }
      try {
        const response = await http.get(`${API_ENDPOINTS.SCOPES_EMISSIONS}?timePeriod=${encodeURIComponent(timePeriod)}&companyId=${company._id}`);
        const { scope1, scope2, scope3 } = response.data;
        setEmissionsData([scope1 / 1000.00, scope2 / 1000.00, scope3 / 1000.00]);
        setTotalEmissions((scope1 + scope2 + scope3) / 1000.00);
      } catch (error) {
        console.error('Error fetching emissions data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [timePeriod]);

  const options = {
    series: emissionsData,
    chart: {
      width: '100%',
      type: 'pie',
    },
    labels: [
      `Scope 1: ${emissionsData[0].toFixed(2)}`,
      `Scope 2: ${emissionsData[1].toFixed(2)}`,
      `Scope 3: ${emissionsData[2].toFixed(2)}`,
    ],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: '100%'
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <h1>Your scope overview</h1>
          {loading ? <Spinner /> : <Chart
            options={options}
            series={options.series}
            type="pie"
            width="100%"
          />}
        </div>
      </div>
    </div>
  );
};

export default PieChartScope;
