import React, { useEffect, useState } from 'react';
import Spinner from "react-bootstrap/Spinner";

const CarbonNeutralWidgetPreview = ({ km, price, currency }) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://public-server-n3bj.onrender.com/scripts/w/carbon-neutral-widget.min.js";
        script.async = true;

        const previewContainer = document.getElementById('carbon-widget-preview');

        if (previewContainer) {
            // Insert the widget HTML
            previewContainer.innerHTML = `
                <div class="carbon-neutral-widget" data-km="${km}" data-price="${price}" data-currency="${currency}"></div>
            `;

            // Append the script
            previewContainer.appendChild(script);

            // Set loading to false when the script is fully loaded
            script.onload = () => {
                setLoading(false);
            };
        }

        return () => {
            // Clean up when the component is unmounted or re-rendered
            if (previewContainer) {
                previewContainer.innerHTML = '';
            }
        };
    }, [km, price, currency]);

    return (
        <div style={{ width: '35%' }}>
            <h5>Widget Preview</h5>
            {loading ? <Spinner animation="border" /> : <div id="carbon-widget-preview"></div>}
        </div>
    );
};

export default CarbonNeutralWidgetPreview;
