// src/utils/generatePDF.js
import jsPDF from "jspdf";
import "jspdf-autotable";

const generatePDF = (reportData) => {
  const doc = new jsPDF();

  // Set header background color
  doc.setFillColor(5, 189, 147); // RGB color for green
  doc.rect(0, 0, 210, 40, 'F'); // A4 width is 210mm, height of 40mm for the header

  // Add title and subtitle
  doc.setFontSize(12);
  doc.setTextColor(255, 255, 255); // Set text color to white
  doc.text("Emissions report", 14, 15);

  doc.setFontSize(16);
  doc.text(reportData.companyName, 14, 22);

  doc.setFontSize(10);
  doc.text(reportData.country, 14, 26);
  doc.text(reportData.sector, 14, 30);
  doc.text(reportData.period, 14, 35);

  // Add right side text
  doc.setFontSize(12);
  doc.text("Business Carbon Calculator", 140, 15);

  doc.setFontSize(10);
  doc.text("by c-mass", 140, 20);

  // Reset text color to black for the rest of the document
  doc.setTextColor(0, 0, 0);

  // Add total emissions section
  doc.setFontSize(12);
  doc.text("Estimated total emissions", 14, 50);
  doc.text("tonnes CO₂e", 160, 50);

  doc.setFontSize(36);
  doc.text(`${reportData.totalEmissions}`, 14, 70);

  // Add line separator
  doc.setDrawColor(0);
  doc.line(14, 75, 200, 75);


  // Add Scope 1 data
  doc.setFontSize(12);
  doc.text("Scope 1", 14, 110);
  doc.autoTable({
    startY: 115,
    head: [["Scope 1", "% of emissions", "tonnes CO₂e"]],
    body: reportData.scope1Data.map(item => [item.scope, item.percentage, item.emissions]),
    styles: { halign: 'center', fontSize: 10 },
    headStyles: { fillColor: [5, 189, 147] }
  });

  // Add Scope 2 data
  doc.setFontSize(12);
  doc.text("Scope 2", 14, doc.autoTable.previous.finalY + 10);
  doc.autoTable({
    startY: doc.autoTable.previous.finalY + 15,
    head: [["Scope 2", "% of emissions", "tonnes CO₂e"]],
    body: reportData.scope2Data.map(item => [item.scope, item.percentage, item.emissions]),
    styles: { halign: 'center', fontSize: 10 },
    headStyles: { fillColor: [5, 189, 147] }
  });

  // Add Scope 3 data
  doc.setFontSize(12);
  doc.text("Scope 3", 14, doc.autoTable.previous.finalY + 10);
  doc.autoTable({
    startY: doc.autoTable.previous.finalY + 15,
    head: [["Scope 3", "% of emissions", "tonnes CO₂e"]],
    body: reportData.scope3Data.map(item => [item.scope, item.percentage, item.emissions]),
    styles: { halign: 'center', fontSize: 10 },
    headStyles: { fillColor: [5, 189, 147] }
  });

  // Save the PDF
  doc.save(`${reportData.companyName}_${reportData.period}_emissions-report.pdf`);
};

export default generatePDF;
