import React, { useState, useContext } from "react";
import ModalVideo from "react-modal-video";
import UserContext from "../../contexts/user/UserContext";
import { Link } from "react-router-dom";
import { Col, Container, Row, Form, Label, Input, FormGroup, Button } from "reactstrap";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import http from '../../frameworks/basic-rest/http';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Section = () => {
  const [isOpen, setOpen] = useState(false);
  const { user } = useContext(UserContext);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    comments: '',
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await http.post(API_ENDPOINTS.INQUIRY, formData);
      toast.success('Email sent successfully!');

      // Clear the form fields
      setFormData({
        name: '',
        email: '',
        number: '',
        comments: '',
      });

    } catch (error) {
      toast.error('Failed to send email. Please try again.');
    }
  };

  return (
    <React.Fragment>
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
            <Row className="align-items-center">
              <Col lg={6}>
                <div className="me-4">
                  <h6 className="sub-title mb-3">c-mass</h6>
                  <h1 className="mb-4">
                    Emission Management{" "}
                    <span className="text-primary">System</span>
                  </h1>
                  <p className="text-muted fs-17">
                    Caluclate your carbon footprint, and buy high quality carbon credit to offset it.
                  </p>
                  <div className="mt-4">

                    {user ? user.completedQuiz ? <Link to="/profile/dashboard" className="btn btn-primary mt-2">
                      Go to my dashboard
                    </Link> : <Link to="/quiz/welcome" className="btn btn-primary mt-2">
                      Caluclate My Carbon Footprint
                    </Link> : <></>}

                  </div>
                </div>
              </Col>
              {user ?  <></>: <Col className="ms-auto" lg={5}>
                      <div className="subscribe-form box-shadow mt-4 mt-lg-0">
                        <div className="text-center mb-4 pb-2">
                          <p className="text-muted text-uppercase mb-2">
                            Welcome to c-mass
                          </p>
                          <h5>Get 30 Days Demo</h5>
                        </div>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <FormGroup>
                        <div className="mb-3">
                          <Label for="name" className="form-label">
                            Name* :
                          </Label>
                          <Input
                            name="name"
                            id="name"
                            type="text"
                            className="form-control"
                            placeholder="Enter your name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <div className="mb-3">
                          <Label for="email" className="form-label">
                            Email address* :
                          </Label>
                          <Input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            placeholder="Enter your email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col lg={6}>
                      <FormGroup>
                        <div className="mb-3">
                          <Label for="number" className="form-label">
                            Phone number* :
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            name="number"
                            id="number"
                            placeholder="Enter your number"
                            value={formData.number}
                            onChange={handleChange}
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col lg={12}>
                      <FormGroup>
                        <div className="mb-3">
                          <Label for="comments" className="form-label">
                            Message :
                          </Label>
                          <Input
                            type="textarea"
                            className="form-control"
                            placeholder="Leave a message here"
                            name="comments"
                            id="comments"
                            style={{ height: "100px" }}
                            value={formData.comments}
                            onChange={handleChange}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <FormGroup>
                        <div className="mt-3 text-end">
                          <Button type="submit" color="primary">
                            Submit
                          </Button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                      </div>
                    </Col>}
            </Row>
          </div>
        </Container>
      </section>
      {/* <!-- END HOME --> */}
      {/* <!-- START SHAPE --> */}
      <div className="position-relative">
        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="1440"
            height="150"
            preserveAspectRatio="none"
            viewBox="0 0 1440 250"
          >
            <g mask='url("#SvgjsMask1036")' fill="none">
              <path
                d="M 0,214 C 96,194 288,120.8 480,114 C 672,107.2 768,201.4 960,180 C 1152,158.6 1344,41.6 1440,7L1440 250L0 250z"
                fill="rgba(255, 255, 255, 1)"
              ></path>
            </g>
            <defs>
              <mask id="SvgjsMask1036">
                <rect width="1440" height="250" fill="#ffffff"></rect>
              </mask>
            </defs>
          </svg>
        </div>
      </div>
      <ModalVideo
        channel="vimeo"
        videoId="287684225"
        autoplay
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
      <ToastContainer />
    </React.Fragment>
  );
};

export default Section;
