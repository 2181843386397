import React, { useState, useContext } from 'react';
import { QuizContext } from '../../contexts/quiz/QuizContext';
import NavbarQuiz from "../../Layout/NavbarQuiz";
import { Col, Container, Row, Progress } from "reactstrap";
import { useNavigate } from 'react-router-dom';

const CompanyRevenuePage = () => {
  const { quizData, updateQuizData } = useContext(QuizContext);
  const [companyRevenue, setCompanyRevenue] = useState(quizData.companyRevenue? quizData.companyRevenue : '');
  const navigate = useNavigate()
  const [totalQuestions, setTotalQuestions] = useState(12);
  const currentQuestion = 3; // This should be dynamically updated based on the current question index

  const handleNextClick = () => {
    if (companyRevenue) {
      updateQuizData({ companyRevenue });
      navigate('/quiz/own-vehicles');
    }
  };

  const handleBackClick = () => {
    navigate('/quiz/employee-count');
  }

  return (
    <React.Fragment>
      <NavbarQuiz />
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
          <div className="progress-wrapper sticky-top mb-3">
                  <div className="d-flex justify-content-between mb-1">
                    <span>{currentQuestion} / {totalQuestions}</span>
                  </div>
                  <Progress value={(currentQuestion / totalQuestions) * 100} color="primary" />
                </div>
            <h6 className="sub-title mb-1">From {quizData.startDate} to {quizData.endDate}</h6>
            <Row className="align-items-center">
              <Col lg={6}>

                <h2>What was your company’s revenue?</h2>
                <p className="text-muted fs-17">
                  Tip: Your finance team would know this
                </p>
                <input
                  type="text"
                  value={companyRevenue}
                  onChange={(e) => setCompanyRevenue(e.target.value)}
                  className="form-control"
                  placeholder="Revenue"
                />
                <div className="mt-2">
                <button onClick={handleBackClick} className="btn btn-primary me-4">
                  Back
                </button>
                <button onClick={handleNextClick} disabled={!companyRevenue} className="btn btn-primary">
                  Next
                </button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Container className="mt-4">
          <Row>
            <Col lg={6}>
              <div className="me-4">
                <h6 className="sub-title mb-1"><i class="bi bi-question-circle"></i>Why are we asking this?</h6>
                <p className="text-muted fs-17">
                  Sharing your basic business data will help us benchmark your emissions against companies of similar size/revenue in the future. It will not be shared externally.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CompanyRevenuePage;
