import React, { useState, useContext } from 'react';
import { QuizContext } from '../../contexts/quiz/QuizContext';
import NavbarQuiz from "../../Layout/NavbarQuiz";
import { Col, Container, Row, FormGroup, Progress } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

const OwnVehiclesPage = () => {
  
  const { quizData, updateQuizData } = useContext(QuizContext);
  const [ownVehicles, setOwnVehicles] = useState(quizData.ownVehicles? quizData.ownVehicles : '');
  const [vehicleCount, setVehicleCount] = useState(quizData.vehicleCount? quizData.vehicleCount : '');
  const navigate = useNavigate();
  const [totalQuestions, setTotalQuestions] = useState(quizData.totalQuestions);
  const currentQuestion = 4; // This should be dynamically updated based on the current question index
 
  const handleClickButton = (e) => {
    setOwnVehicles(e.target.value);
    if (e.target.value == 'no') {
      if(quizData.vFuelConsumption) updateQuizData({vFuelConsumption: 0});
      let updatedTQ = totalQuestions - 1;
      setTotalQuestions(updatedTQ);
    }
    else {
      if(quizData.totalQuestions != 12) {
        let updatedTQ = totalQuestions + 1;
        setTotalQuestions(updatedTQ);
      }
      else {
        setTotalQuestions(quizData.totalQuestions);
      }
    }
  }

  const handleBackClick = () => {
    navigate('/quiz/revenue');
  }

  const handleNextClick = () => {
    if (ownVehicles) {
      updateQuizData({ ownVehicles, vehicleCount,  totalQuestions, currentQuestion: 5});
      if (ownVehicles == 'yes') {
        
        navigate('/quiz/vfuel-consumption');
      }
      else {
        navigate('/quiz/own-machinery');
      }
    }
  };

  return (
<React.Fragment>
  <NavbarQuiz />
  <section className="bg-home5" id="home">
    <div className="bg-overlay"></div>
    <Container>
      <div className="position-relative" style={{ zIndex: "1" }}>
        <div className="progress-wrapper sticky-top mb-3">
          <div className="d-flex justify-content-between mb-1">
            <span>{currentQuestion} / {totalQuestions}</span>
          </div>
          <Progress value={(currentQuestion / totalQuestions) * 100} color="primary" />
        </div>
        <h6 className="sub-title mb-1">From {quizData.startDate} to {quizData.endDate}</h6>

        <Row className="align-items-center">
          <Col lg={6}>
            <h2>Did your company own or maintain long-term leases on vehicles?</h2>
            <p className="text-muted fs-17">Example: Cars or trucks</p>
            <div>
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <label>
                      <Form.Check
                        type="radio"
                        value="yes"
                        checked={ownVehicles === 'yes'}
                        onChange={(e) => handleClickButton(e)}
                        label="Yes"
                      />
                    </label>
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <label>
                      <Form.Check
                        type="radio"
                        value="no"
                        checked={ownVehicles === 'no'}
                        onChange={(e) => handleClickButton(e)}
                        label="No"
                      />
                    </label>
                  </FormGroup>
                </Col>
              </Row>
              {ownVehicles === 'yes' && (
                <Row className="mt-3">
                  <Col lg={6}>
                    <FormGroup>
                      <label>How many vehicles?</label>
                      <Form.Control
                        type="number"
                        value={vehicleCount}
                        onChange={(e) => setVehicleCount(e.target.value)}
                        placeholder="Enter number of vehicles"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </div>
            <div className="mt-2">
              <button onClick={handleBackClick} className="btn btn-primary me-4">
                Back
              </button>
              <button onClick={handleNextClick} disabled={!ownVehicles} className="btn btn-primary">
                Next
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
    <Container className="mt-4">
      <Row>
        <Col lg={6}>
          <div className="me-4">
            <h6 className="sub-title mb-1"><i class="bi bi-question-circle"></i>Why are we asking this?</h6>
            <p className="text-muted fs-17">
              This represents direct emissions from your company’s activities.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
</React.Fragment>

  );
};

export default OwnVehiclesPage;
