import React from "react";
import NavBar from "../../Layout/Navbar";  // If you want to keep the navbar

const EC = () => {
  return (
    <React.Fragment>
      <NavBar /> 
      {/* Your multi-page quiz content goes here */}
    </React.Fragment>
  );
};

export default EC;
