import React, { createContext, useState } from 'react';

export const QuizContext = createContext();

export const QuizProvider = ({ children }) => {
  const [quizData, setQuizData] = useState({});

  const updateQuizData = (newData) => {
    setQuizData((prevData) => ({
      ...prevData, 
      ...newData,
    }));
  };

  return (
    <QuizContext.Provider value={{ quizData, updateQuizData }}>
      {children}
    </QuizContext.Provider>
  );
};
