export const API_ENDPOINTS = {
    SECTORS: '/sectors',
    SAVE_COMPANY_DATA: '/company/save-company-data',
    LOGIN: '/user/login',
    SIGNUP: '/user/signup',
    SCOPES_EMISSIONS: '/emissions/scopes',
    TOP_CONTRIBUTORS: '/emissions/top-emissions',
    FULL_REPORT: '/emissions/full-report',
    COMPANY: '/company',
    PURCHASE_ORDER: '/company/order',
    COMPANY_ORDERS_SUMMERY: '/company/ordersSummery',
    COMPANY_ORDERS: '/company/orders',
    PORTFOLIO: '/portfolio',
    GET_PORTFOLIO: '/portfolio/getPortfolio',
    INQUIRY: '/mail/inquiry',
    ORDER_DETAILS: '/company/orders',
    CREATE_SANDBOX_APIKEY: '/v1/apikeys/create',
    REVOKE_SANDBOX_APIKEY: '/v1/apikeys/revoke',
    GET_LIVE_API_KEYS: '/v1/apikeys/live',
    GET_SANDBOX_API_KEYS: '/v1/apikeys/sandbox',
    PUBLIC_SHARE_IMPACT: '/public/share-impact'
}