// src/components/TimePeriodDropdown.js
import React, { useState } from 'react';
import '../assets/css/TimePeriodDropdown.css';

const TimePeriodDropdown = ({ className, timePeriods, setTimePeriod }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState(timePeriods[0] || "Select Time Period");

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleTimePeriodChange = (period) => {
        setSelectedPeriod(period);
        setTimePeriod(period);
        setDropdownOpen(false); // Close the dropdown upon selection
    };

    return (
        <div className={`custom-dropdown ${className}`} onClick={toggleDropdown}>
            <div className="dropdown-toggle-custom">
                <div className="dropdown-header">
                    <i className="bi bi-clock"></i>
                    <span>Time Period</span>
                </div>
                <div className="dropdown-selected">
                    {selectedPeriod}
                    <span className={`dropdown-toggle-icon ${dropdownOpen ? 'rotate' : ''}`}>
                        ▼ {/* Arrow icon for dropdown */}
                    </span>
                </div>
            </div>
            <ul className={`dropdown-menu ${dropdownOpen ? "show" : ""}`}>
                {timePeriods.map((period, index) => (
                    <li key={index} className="dropdown-item" onClick={() => handleTimePeriodChange(period)}>
                        {period}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TimePeriodDropdown;
