import React, { useState, useContext } from 'react';
import { QuizContext } from '../../contexts/quiz/QuizContext';
import NavbarQuiz from "../../Layout/NavbarQuiz";
import { Col, Container, Row, Button, Progress } from "reactstrap";
import { Accordion, Tooltip, OverlayTrigger } from 'react-bootstrap'; // Import Accordion from react-bootstrap
import { useNavigate } from 'react-router-dom';

const ExpensesPage = () => {
  const [expenses, setExpenses] = useState({
    businessTravel: {
      flights: '',
      busTickets: '',
      carRentals: ''
    },
    materialsInventory: {
      furniture: '',
      airConditioning: '',
      paperPackaging: '',
      textiles: '',
      plasticProducts: ''
    },
    capitalGoods: {
      phones: '',
      computers: ''
    },
    services: {
      legalServices: '',
      softwareHosting: ''
    }
  });
  const { quizData, updateQuizData } = useContext(QuizContext);
  const navigate = useNavigate();
  const totalExpenses = quizData.totalExpenses || 0;

  const calculateFilledExpenses = () => {
    let filledExpenses = 0;
    Object.values(expenses).forEach(category => {
      Object.values(category).forEach(expense => {
        filledExpenses += parseFloat(expense) || 0;
      });
    });
    return filledExpenses;
  };

  const filledExpenses = calculateFilledExpenses();
  const progress = (filledExpenses / totalExpenses) * 100;
  let coverageLabel = 'Low';

  if (progress > 50 && progress <= 80) {
    coverageLabel = 'Okay';
  } else if (progress > 80) {
    coverageLabel = 'Great';
  }

  const handleChange = (e, category, item) => {
    const updatedExpenses = { ...expenses };
    updatedExpenses[category][item] = e.target.value;
    setExpenses(updatedExpenses);
  };

  const handleNextClick = () => {
    console.log(expenses);
    updateQuizData({ expenses });
    navigate('/quiz/carbon-footprint-summary');
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      For a more complete carbon estimate, try to match at least 80% of your total expenses to specific expense categories if possible.
    </Tooltip>
  );

  return (
    <React.Fragment>
      <NavbarQuiz />
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container className="container-with-padding">
          <div className="position-relative" style={{ zIndex: "1" }}>
          <h6 className="sub-title mb-1">From {quizData.startDate} to {quizData.endDate}</h6>
            <div className="fixed-progress-bar">
              <Row className="align-items-center">
                <Col>
                  <h5>Expense coverage: {coverageLabel} <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
                      <i className="bi bi-info-circle ml-2"></i>
                    </OverlayTrigger></h5>
                  
                  <Progress value={progress} color="primary" />
                </Col>
                <Col className="text-right">
                  <h5>Total expenses: SAR {totalExpenses}</h5>
                  <h5>Filled: SAR {filledExpenses}</h5>
                </Col>
              </Row>
            </div>
            <Row className="align-items-center mt-5">
              <Col lg={12}>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Business Travel</Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col lg={6}>
                          <input
                            type="number"
                            value={expenses.businessTravel.flights}
                            onChange={(e) => handleChange(e, 'businessTravel', 'flights')}
                            className="form-control"
                            placeholder="Business Flights"
                          />
                          <input
                            type="number"
                            value={expenses.businessTravel.busTickets}
                            onChange={(e) => handleChange(e, 'businessTravel', 'busTickets')}
                            className="form-control"
                            placeholder="Bus Tickets"
                          />
                          <input
                            type="number"
                            value={expenses.businessTravel.carRentals}
                            onChange={(e) => handleChange(e, 'businessTravel', 'carRentals')}
                            className="form-control"
                            placeholder="Car Rentals"
                          />
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Materials / Inventory</Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col lg={6}>
                          <input
                            type="number"
                            value={expenses.materialsInventory.furniture}
                            onChange={(e) => handleChange(e, 'materialsInventory', 'furniture')}
                            className="form-control"
                            placeholder="Furniture"
                          />
                          <input
                            type="number"
                            value={expenses.materialsInventory.airConditioning}
                            onChange={(e) => handleChange(e, 'materialsInventory', 'airConditioning')}
                            className="form-control"
                            placeholder="Air-conditioning Equipments"
                          />
                          <input
                            type="number"
                            value={expenses.materialsInventory.paperPackaging}
                            onChange={(e) => handleChange(e, 'materialsInventory', 'paperPackaging')}
                            className="form-control"
                            placeholder="Paper and Packaging"
                          />
                          <input
                            type="number"
                            value={expenses.materialsInventory.textiles}
                            onChange={(e) => handleChange(e, 'materialsInventory', 'textiles')}
                            className="form-control"
                            placeholder="Textiles"
                          />
                          <input
                            type="number"
                            value={expenses.materialsInventory.plasticProducts}
                            onChange={(e) => handleChange(e, 'materialsInventory', 'plasticProducts')}
                            className="form-control"
                            placeholder="Plastic Products"
                          />
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Capital Goods</Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col lg={6}>
                          <input
                            type="number"
                            value={expenses.capitalGoods.phones}
                            onChange={(e) => handleChange(e, 'capitalGoods', 'phones')}
                            className="form-control"
                            placeholder="Phones, TV, and Communication Equipment"
                          />
                          <input
                            type="number"
                            value={expenses.capitalGoods.computers}
                            onChange={(e) => handleChange(e, 'capitalGoods', 'computers')}
                            className="form-control"
                            placeholder="Computers and Office Machinery"
                          />
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Services</Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col lg={6}>
                          <input
                            type="number"
                            value={expenses.services.legalServices}
                            onChange={(e) => handleChange(e, 'services', 'legalServices')}
                            className="form-control"
                            placeholder="Legal, Accounting and Management Consultancy Services"
                          />
                          <input
                            type="number"
                            value={expenses.services.softwareHosting}
                            onChange={(e) => handleChange(e, 'services', 'softwareHosting')}
                            className="form-control"
                            placeholder="Software, Hosting, Computer Programming & Related Activities"
                          />
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <button onClick={handleNextClick} className="btn btn-primary mt-2">
                  Submit
                </button>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ExpensesPage;
