import React, { useState, useContext } from 'react';
import { QuizContext } from '../../contexts/quiz/QuizContext';
import NavbarQuiz from "../../Layout/NavbarQuiz";
import { Col, FormGroup, Row, Container, Progress } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

const RenewableElectricityPage = () => {
  const { quizData, updateQuizData } = useContext(QuizContext);
  const [renewableElectricity, setRenewableElectricity] = useState(quizData.renewableElectricity? quizData.renewableElectricity : '');
  const navigate = useNavigate();
  const [totalQuestions, setTotalQuestions] = useState(quizData.totalQuestions);
  const currentQuestion = quizData.currentQuestion; // This should be dynamically updated based on the current question index

  const handleNextClick = () => {
    if (renewableElectricity) {
      updateQuizData({ renewableElectricity,  currentQuestion: currentQuestion+1 });
      navigate('/quiz/heating-received');
    }
  };

  const handleBackClick = () => {
    updateQuizData({ currentQuestion: currentQuestion-1});
      navigate('/quiz/electricity-consumption');    
  }

  return (
    <React.Fragment>
      <NavbarQuiz />
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
            <div className="progress-wrapper sticky-top mb-3">
              <div className="d-flex justify-content-between mb-1">
                <span>{currentQuestion} / {totalQuestions}</span>
              </div>
              <Progress value={(currentQuestion / totalQuestions) * 100} color="primary" />
            </div>
            <h6 className="sub-title mb-1">From {quizData.startDate} to {quizData.endDate}</h6>
            <Row className="align-items-center">
              <Col lg={6}>
                <h2>Did your company receive 100% renewable electricity?</h2>
                <p className="text-muted fs-17">Tip: This is usually stated on your electricity bill</p>
                <div>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <label>
                          <Form.Check
                            type="radio"
                            value="yes"
                            checked={renewableElectricity === 'yes'}
                            onChange={(e) => setRenewableElectricity(e.target.value)}
                            label="Yes"
                          />
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <label>
                          <Form.Check
                            type="radio"
                            value="no"
                            checked={renewableElectricity === 'no'}
                            onChange={(e) => setRenewableElectricity(e.target.value)}
                            label="No"
                          />
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="mt-2">
                <button onClick={handleBackClick} className="btn btn-primary me-4">
                  Back
                </button>
                <button onClick={handleNextClick} disabled={!renewableElectricity} className="btn btn-primary">
                  Next
                </button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Container className="mt-4">
          <Row>
            <Col lg={6}>
              <div className="me-4">
                <h6 className="sub-title mb-1"><i class="bi bi-question-circle"></i>Why are we asking this?</h6>
                <p className="text-muted fs-17">
                  If your company buys 100% renewable electricity from its electricity supplier, the corresponding energy usage will produce zero emissions. This is often shown on your invoice and likely has an additional cost.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default RenewableElectricityPage;
