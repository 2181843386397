import React, { useState, useContext } from 'react';
import { QuizContext } from '../../contexts/quiz/QuizContext';
import NavbarQuiz from "../../Layout/NavbarQuiz";
import { Col, Container, Row, Button, Progress  } from "reactstrap";
import { useNavigate } from 'react-router-dom';

const CompanyLocationPage = () => {
  const [companyLocation, setCompanyLocation] = useState('');
  const { updateQuizData } = useContext(QuizContext);
  const navigate = useNavigate()

  const handleInputChange = (e) => {
    setCompanyLocation(e.target.value);
  };

  const handleNextClick = () => {
    if (companyLocation) {
      updateQuizData({ companyLocation });
      navigate('/quiz/company-sector');

    }
  };

  return (
    <React.Fragment>
      <NavbarQuiz />
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
            <Row className="align-items-center">
              <Col lg={6}>
                <h2>Where is your company located?</h2>
                <p>The Business Carbon Calculator makes key assumptions based on your country of operation. If you operate or have substantial business activities based in more than one country, our tool won’t be able to give you an accurate carbon footprint estimate.</p>
                <input
                  type="text"
                  value={companyLocation}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Location"
                />
                <button onClick={handleNextClick} disabled={!companyLocation} className="btn btn-primary mt-2">
                  Next
                </button>

              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CompanyLocationPage;
