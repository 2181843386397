// src/components/DonuChart.js
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import http from '../frameworks/basic-rest/http';
import { API_ENDPOINTS } from '../frameworks/basic-rest/api-endpoints';
import Spinner from 'react-bootstrap/Spinner';

const DonutChart = ({ timePeriod, company }) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({
    series: [],
    chart: {
      type: 'donut',
    },
    labels: [],
    legend: {
      show: true,
        position: 'bottom',
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: '100%',
        },
        legend: {
          show: true,
        position: 'bottom',
        },
      },
    }],
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!timePeriod) {
        console.log("timePeriod is not available, skipping request.");
        return; // Exit early if timePeriod is not yet available
      }
      try {
        const response = await http.get(`${API_ENDPOINTS.TOP_CONTRIBUTORS}?timePeriod=${encodeURIComponent(timePeriod)}&companyId=${company._id}`);
        const data = response.data;

        const series = data.map(item => item.emissions);
        const labels = data.map(item => item.type);

        setOptions(prevOptions => ({
          ...prevOptions,
          series,
          labels,
        }));
      } catch (error) {
        console.error('Error fetching emissions data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [timePeriod]);

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <h1>Top Emissions By Type</h1>
          {loading ? <Spinner /> : <Chart
            options={options}
            series={options.series}
            type="donut"
            width="100%"
          />}
        </div>
      </div>
    </div>
  );
};

export default DonutChart;