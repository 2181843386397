import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem, Collapse } from 'reactstrap';
import logo from '../assets/images/nz-logo.png'; // Adjust the path as necessary
import UserContext from "../contexts/user/UserContext";
import '../assets/css/sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'; // Import the arrow icons

const Sidebar = ({ company }) => {
    const { user } = useContext(UserContext);
    const [isCollapsed, setIsCollapsed] = useState(window.innerWidth <= 768);
    const [impactOpen, setImpactOpen] = useState(false); // State for Impact collapse
    const [developersOpen, setDevelopersOpen] = useState(false); // State for Developers collapse

    useEffect(() => {
        if (!user) {
            console.log("User is not available, skipping request.");
            return; // Exit early if user is not yet available
        }
    }, [user]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setIsCollapsed(true);
            } else {
                setIsCollapsed(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const toggleImpact = () => setImpactOpen(!impactOpen);
    const toggleDevelopers = () => setDevelopersOpen(!developersOpen);

    return (
        <>
            <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
                <div className="sidebar-header">
                    <a href="/"><img src={logo} alt="Logo" className="logo" style={{ width: '100px', height: 'auto' }} /></a>
                    <h3>{company.name}</h3>
                    <p><i className="bi bi-pin-map-fill"></i> {company.location}</p>
                    <p><i className="bi bi-buildings-fill"></i> {company.industry}</p>
                </div>
                <hr style={{ border: '1px solid #000', width: '90%', margin: '10px auto' }} />
                <Nav vertical>

                    {/* Dashboard */}
                    <NavItem>
                        <NavLink to="/profile/dashboard" className="nav-link"><i className="bi bi-clipboard-data"></i> Dashboard</NavLink>
                    </NavItem>

                    {/* Offset */}
                    <NavItem>
                        <NavLink to="/profile/offset" className="nav-link"><i className="bi bi-currency-exchange"></i> Offset</NavLink>
                    </NavItem>

                    {/* Portfolio */}
                    <NavItem>
                        <NavLink to="/profile/choose-portfolio" className="nav-link"><i className="bi bi-briefcase"></i> Portfolio</NavLink>
                    </NavItem>

                    {/* Orders */}
                    <NavItem>
                        <NavLink to="/profile/orders" className="nav-link"><i className="bi bi-list-task"></i> Orders</NavLink>
                    </NavItem>

                    {/* Impact Section */}
                    <NavItem onClick={toggleImpact} style={{ cursor: 'pointer' }}>
                        <div className="nav-link">
                            <i className="bi bi-globe-americas"></i> Impact
                            <FontAwesomeIcon
                                icon={impactOpen ? faChevronDown : faChevronRight}
                                className="arrow" />
                        </div>
                    </NavItem>
                    <Collapse isOpen={impactOpen}>
                        <NavItem>
                            <NavLink to="/profile/my-impact" className="nav-link sub-link">
                                <i className="bi bi-tree"></i> My Impact
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/profile/impact-sharing" className="nav-link sub-link">
                                <i className="bi bi-megaphone"></i> Sharing
                            </NavLink>
                        </NavItem>
                    </Collapse>

                    {/* Developers Section */}
                    <NavItem onClick={toggleDevelopers} style={{ cursor: 'pointer' }}>
                        <div className="nav-link">
                            <i className="bi bi-code-slash"></i> Developers
                            <FontAwesomeIcon
                                icon={developersOpen ? faChevronDown : faChevronRight}
                                className="arrow" />
                        </div>
                    </NavItem>
                    <Collapse isOpen={developersOpen}>
                        <NavItem>
                            <NavLink to="/profile/developer" className="nav-link sub-link">
                                <i className="bi bi-file-code"></i> APIs
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/profile/widgets" className="nav-link sub-link">
                                <i className="bi bi-window-desktop"></i> Widgets
                            </NavLink>
                        </NavItem>
                    </Collapse>

                </Nav>
            </div>
            <button className="sidebar-toggle" onClick={toggleSidebar}>
                {isCollapsed ? 'Expand' : 'Collapse'}
            </button>
        </>
    );
};

export default Sidebar;
