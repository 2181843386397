import React, { useState, useContext } from 'react';
import { QuizContext } from '../../contexts/quiz/QuizContext';
import NavbarQuiz from "../../Layout/NavbarQuiz";
import { Col, Container, Row } from "reactstrap";
import { useNavigate } from 'react-router-dom';

const TotalExpensesPage = () => {
  const [totalExpenses, setTotalExpenses] = useState('');
  const { quizData, updateQuizData } = useContext(QuizContext);
  const navigate = useNavigate()

  const handleNextClick = () => {
    if (totalExpenses) {
      updateQuizData({ totalExpenses });
      navigate('/quiz/expenses');
    }
  };

  return (
    <React.Fragment>
      <NavbarQuiz />
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
          <h6 className="sub-title mb-1">From {quizData.startDate} to {quizData.endDate}</h6>
            <Row className="align-items-center">
              <Col lg={6}>
                <h2>What were your company’s total expenses?</h2>
                <p className="text-muted fs-17">Tip: Excludes salary, taxes, Zakah, and any costs pertaining to fuel usage, heating, or electricity covered in previous questions</p>
                <input
                  type="number"
                  value={totalExpenses}
                  onChange={(e) => setTotalExpenses(e.target.value)}
                  className="form-control"
                  placeholder="Total Expenses"
                />
                <button onClick={handleNextClick} disabled={!totalExpenses} className="btn btn-primary mt-2">
                  Next
                </button>
              </Col>
            </Row>
          </div>
        </Container>
        <Container className="mt-4">
          <Row>
            <Col lg={6}>
              <div className="me-4">
                <h6 className="sub-title mb-1"><i class="bi bi-question-circle"></i>Why are we asking this?</h6>
                <p className="text-muted fs-17">
                Purchases made by your company result in indirect emissions. These are commonly considered to be supply chain emissions and are part of your company’s carbon footprint. Exclude any heating, electricity and fuel costs that are already covered in previous questions.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default TotalExpensesPage;
