import React, { useState } from "react";
import { Col, Container, Row, Form, Label, Input, FormGroup, Button } from "reactstrap";
import { API_ENDPOINTS } from "../frameworks/basic-rest/api-endpoints";
import http from '../frameworks/basic-rest/http';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Card = (props) => {
  return (
    <React.Fragment>
      <Col lg={3} md={6}>
        <div className="contact-info text-center mt-5">
          <div className="icon">
            <i className={props.iconClass}></i>
          </div>
          <div className="mt-4 pt-2">
            <h6 className="fs-17">{props.title}</h6>
            {props.isChildItem.map((item, key) => (
              <p className="text-muted mb-0" key={key}>
                {item}
              </p>
            ))}
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

// Contact Data
const ContactData = [
  {
    title: "Main Office",
    iconClass: "mdi mdi-map-marker text-primary h4",
    isChildItem: ["Dhahran, Abu Bakr Street"],
  },
  {
    title: "Phone",
    iconClass: "mdi mdi-phone text-primary h4",
    isChildItem: ["0534006112"],
  },
  {
    title: "Email",
    iconClass: "mdi mdi-email text-primary h4",
    isChildItem: ["abdulrahman@c-mass.co"],
  },
  {
    title: "Working Hours",
    iconClass: "mdi mdi-calendar-clock text-primary h4",
    isChildItem: ["Sunday-thursday: 7:00-04:00", "Friday-Saturday: Holiday"],
  },
];

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    comments: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await http.post(API_ENDPOINTS.INQUIRY, formData);
      toast.success('Email sent successfully!');

      // Clear the form fields
      setFormData({
        name: '',
        email: '',
        number: '',
        comments: '',
      });

    } catch (error) {
      toast.error('Failed to send email. Please try again.');
    }
  };

  return (
    <React.Fragment>
      <section className="section" id="contact">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="text-center mb-4">
                <p className="text-uppercase text-muted mb-2">Contact</p>
                <h3 className="text-uppercase">Get In Touch</h3>
                <div className="title-border mt-3"></div>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            {/* Contact Component Start */}
            {ContactData.map((value, idx) => (
              <Card
                key={idx}
                title={value.title}
                iconClass={value.iconClass}
                isChildItem={value.isChildItem}
              />
            ))}
            {/* Contact Component End */}
          </Row>

          <Row className="mt-5 pt-2 justify-content-center">
            <Col lg={9}>
              <div className="custom-form">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <FormGroup>
                        <div className="mb-3">
                          <Label for="name" className="form-label">
                            Name* :
                          </Label>
                          <Input
                            name="name"
                            id="name"
                            type="text"
                            className="form-control"
                            placeholder="Enter your name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <div className="mb-3">
                          <Label for="email" className="form-label">
                            Email address* :
                          </Label>
                          <Input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            placeholder="Enter your email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col lg={6}>
                      <FormGroup>
                        <div className="mb-3">
                          <Label for="number" className="form-label">
                            Phone number* :
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            name="number"
                            id="number"
                            placeholder="Enter your number"
                            value={formData.number}
                            onChange={handleChange}
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col lg={12}>
                      <FormGroup>
                        <div className="mb-3">
                          <Label for="comments" className="form-label">
                            Comments :
                          </Label>
                          <Input
                            type="textarea"
                            className="form-control"
                            placeholder="Leave a comment here"
                            name="comments"
                            id="comments"
                            style={{ height: "100px" }}
                            value={formData.comments}
                            onChange={handleChange}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <FormGroup>
                        <div className="mt-3 text-end">
                          <Button type="submit" color="primary">
                            Submit
                          </Button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <ToastContainer />
    </React.Fragment>
  );
};

export default Contact;
