// src/pages/ChoosePortfolio.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Button } from 'reactstrap';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import '../../assets/css/choosePortfolio.css';
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useOutletContext } from 'react-router-dom';

const ChoosePortfolio = () => {
    const [portfolios, setPortfolios] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPortfolios = async () => {
            try {
                const portfolioResponse = await http.get(API_ENDPOINTS.PORTFOLIO);
                setPortfolios(portfolioResponse.data.portfolios);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching portfolios:", error);
                setLoading(false);
            }
        };

        fetchPortfolios();
    }, []);

    const handlePortfolioClick = (portfolioId) => {
        navigate(`/profile/portfolio/${portfolioId}`);
      };

return (
        <Container className="choose-portfolio-container">
            <h1>Choose portfolio to explore</h1>
            {loading ? <Spinner /> : <Row>
                {portfolios?.map((portfolio) => (
                    <Col md="6" key={portfolio._id}>
                        <Card className="portfolio-card card" style={{borderRadius: '25px'}} onClick={() => handlePortfolioClick(portfolio._id)}>
                            <CardBody className='card-body' style={{padding: '0'}}>
                                <div className="portfolio-image" style={{ backgroundImage: `url(${portfolio.img})`, borderRadius: '25px 25px 0px 0px' }}></div>
                                <CardTitle tag="h3">{portfolio.name}</CardTitle>
                                <CardText>{portfolio.description}</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>}
        </Container>
    );
};

export default ChoosePortfolio;
